import axios from "axios";
import { set, isEmpty, get } from "lodash";
import { datadogRum } from '@datadog/browser-rum';
import { getCookies, getUrlAuthToken } from ".";
import servicesUtils from "./servicesUtils";
import redirectToLogin from "./redirectToLogin";

const TIMEOUT = 180000;

const axiosConnect = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  timeout: TIMEOUT,
  withCredentials: true,
  // exposedHeaders: ["set-cookie"] // there is no such key called exposedHeaders in type AxiosRequestConfig
});

axiosConnect.interceptors.request.use(async function interceptorFunc(config) {
  if (
    config.method === "post" ||
    config.method === "patch" ||
    config.method === "put" ||
    config.method === "delete"
  ) {
    const cookies = getCookies();
    const csrfToken = get(cookies, ["csrftoken"], "");
    if (csrfToken) {
      set(config, ["headers", servicesUtils.X_CSRF], csrfToken);
    }
  }

  const qXRProjectURLs = ["/auth/", "/login/", "/logout/", "/csrf/"];
  if (config.url && qXRProjectURLs.indexOf(config.url) === -1) {
    set(config, ["url"], `${config.url}`);
  }

  const { baseURL, url, params } = config;
  const isUserInfoCall = url?.includes("/userinfo") || url?.includes("/profile");

  const urlAuthToken = getUrlAuthToken();
  if (urlAuthToken && !isUserInfoCall && params) {
    params["url_auth_token"] = urlAuthToken;
  }

  if (isEmpty(baseURL)) {
    /* eslint-disable prefer-promise-reject-errors */
    return Promise.reject({ noValidUrl: true });
  }
  return config;
});

axiosConnect.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (err) => {
    const errorStatus = get(err, ["response", "status"]);
    const isUserInfoCall = get(err, ["config", "url"], "").includes("/userinfo");
    const stopRedirectLogin = get(err, ["config", "params", "stopRedirectLogin"], false);
    const isUrlAuthToken = !!getUrlAuthToken();
    // if ([400, 403, 404, 500, 502, 503, 504].indexOf(errorStatus) !== -1) {
    //   // log 4xx, 5xx errors, not logging 401
    //   SentryIntegration.logError(err)
    // }

    if (
      errorStatus === 401 &&
      !stopRedirectLogin &&
      window.location.pathname !== "/landing-page" &&
      window.location.pathname !== "/notifications-widget" &&
      !isUrlAuthToken
    ) {
      const redirectLogin = localStorage.getItem("redirectLogin");
      if (redirectLogin) {
        localStorage.removeItem("redirectLogin");
        redirectToLogin();
      } else {
        window.location.href = window.location.origin + "/landing-page";
      }
    } else {
      /* eslint-disable no-console */
      console.error("Invalid request", err);
      datadogRum.addError(err);
    }
    return Promise.reject(err);
  }
);

export default axiosConnect;
