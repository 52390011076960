import { Store } from "pullstate";

export interface IStore {
  fcmToken: string | null;
  user: Record<any, any>;
  userInfo: any;
  uploadModal: {
    isOpen: boolean;
    hasCurrentPatient: boolean;
  };
  isAddPatientOpen: boolean;
  isEditPatientOpen: boolean;
  isConnectModalityOpen: boolean;
  isLogoutOpen: boolean;
  sharingPatient: string;
  isGetFullAccessOpen: boolean;
  enableViewerAPICall: boolean;
  patient: Record<any, any>;
  patientDetail: Record<any, any>;
  isDarkMode: boolean | null;
  isSettingsModalOpen: boolean;
  isNotificationSettingsOpen: boolean;
  isChangeRegionModalOpen: boolean;
  isReportTemplateOpen: boolean;
  isFindingsSettingsOpen: boolean;
  isAutomatedSharingOpen: boolean;
  isAutomatedConditionOpen: boolean;
  isUserGroupOpen: boolean;
  isCreateUserGroup: boolean;
  isAutomatedFindingsOpen: boolean;
  isFormSelectorVisible: boolean;
  formName: string;
  formId: string;
  isIncomingForm: boolean;
  isMatchingPatientOpen: boolean;
  isWorkspaceOpen: boolean;
  emergencyPatientId: string | null;
  isEmergencyModalOpen: boolean;
  isConfirmationModalOpen: boolean;
  userSettings: Record<any, any>;
  appConfig: Record<any, any>;
  isRNTestBuild: boolean;
  RNSelectedEnv: string;
  isRNEnvSelectorOpen: boolean;
  downloadExport: boolean;
  isExportReady: boolean;
  downloadProgress: number;
  exportTimestamp: string;
  exportGenFailed: boolean;
  showExportUpdate: boolean;
  dashboardUrl: string | null;
}

export default new Store<IStore>({
  fcmToken: null,
  user: {},
  userInfo: {},
  uploadModal: {
    isOpen: false,
    hasCurrentPatient: false,
  },
  isAddPatientOpen: false,
  isEditPatientOpen: false,
  isConnectModalityOpen: false,
  isLogoutOpen: false,
  sharingPatient: "",
  isGetFullAccessOpen: true,
  enableViewerAPICall: false,
  patient: {},
  patientDetail: {},
  isDarkMode: null,
  isSettingsModalOpen: false,
  isNotificationSettingsOpen: false,
  isChangeRegionModalOpen: false,
  isReportTemplateOpen: false,
  isFindingsSettingsOpen: false,
  isAutomatedSharingOpen: false,
  isAutomatedConditionOpen: false,
  isUserGroupOpen: false,
  isCreateUserGroup: false,
  isAutomatedFindingsOpen: false,
  isMatchingPatientOpen: false,
  isFormSelectorVisible: false,
  formName: "",
  formId: "",
  isIncomingForm: false,
  isWorkspaceOpen: false,
  emergencyPatientId: null,
  isEmergencyModalOpen: false,
  isConfirmationModalOpen: false,
  userSettings: {},
  appConfig: {},
  isRNTestBuild: false,
  RNSelectedEnv: "PROD",
  isRNEnvSelectorOpen: false,
  downloadExport : false,
  isExportReady : false,
  downloadProgress : 0,
  exportTimestamp : '',
  exportGenFailed: false,
  showExportUpdate: false,
  dashboardUrl: null
});
