const PAGE_SIZE = 10;
const GENDERS = [
  { id: 0, label: "Male", shortLabel: "M", value: 0 },
  { id: 1, label: "Female", shortLabel: "F", value: 1 },
  { id: 2, label: "Other", shortLabel: "O", value: 2 },
  // { id: 3, label: "NA", shortLabel: "", value: 3 },
];

const AI_INFERENCE = {
  WAITING_FOR_SCAN: 0,
  PROCESSING: 1,
  NEGATIVE: 2,
  POSITIVE: 3,
  URGENT: 4,
  REJECTED: -1,
  FAILED: -2,
  NA: -3,
};

const STATUS_OPTIONS = [
  // { label: 'Waiting for scan', value: AI_INFERENCE.WAITING_FOR_SCAN },
  // { label: 'Scan Acquired', value: AI_INFERENCE.PROCESSING },
  { label: "Negative", value: AI_INFERENCE.NEGATIVE },
  { label: "Positive", value: AI_INFERENCE.POSITIVE },
  { label: "Critical", value: AI_INFERENCE.URGENT },
  { label: "Invalid Scan", value: AI_INFERENCE.REJECTED },
  { label: "Processing Error", value: AI_INFERENCE.FAILED },
];

const RN_COMM_CONST = {
  APP_READY: "APP_READY",
  FLUSH_COOKIES: "FLUSH_COOKIES",
  LOGIN: "LOGIN",
  SHARE: "SHARE",
  ENV_SELECTION: "ENV_SELECTION",
  REGION_URL: "REGION_URL"
};

const SCREENS = {
  WORKLIST: "patients",
  VIEWER: "viewer",
};

const MEDIA_TYPES = {
  IMAGE: "IMAGE",
  DOCUMENT: "DOCUMENT",
  STUDY: "STUDY",
  REPORT: "REPORT",
  FORM: "FORM",
  AUDIO: "AUDIO",
};

const IMAGE_TYPES = ["jpg", "jpeg", "png", "webp"];

const DOC_TYPES = ["pdf", "msword", "doc", "docx", "xls", "xlsx"];

const AUDIO_TYPES = ["mp3", "mpga", "weba", "webm"];

const PLATFORMS = {
  ANDROID: 0,
  PWA: 1,
  IOS: 2,
};

const FORMS = {
  STROKE_CLINICAL_INFO: "stroke_clinical",
  STROKE_NIHSS: "nihss",
  TB_SYMPTOMS: "symptoms",
  TB_LABTESTS: "tb_lab_test",
  TB_SPUTUM: "tb-sputum",
  TB_GENEXPERT: "tb-genexpert",
};

const NOTIFICATION_EVENTS = {
  NEW_PATIENT: 0,
  POSITIVE_FINDINGS: 2,
  NEGATIVE_FINDINGS: 3,
  REPORT_UPDATE: 4,
  STROKE_INITIATED: 5,
  PATIENT_SHARED: 7,
};

const FORM_FIELDS = {
  DUAL_NUMBER: "dual_number",
  NUMBER: "number",
  MULTISELECT: "multiselect",
  CHECKBOX: "check_box",
  SINGLESELECT: "singleselect",
  DATETIME: "datetime",
  LKWT: "lkwt",
};

const DEEFAULT_FILTER = {
  isFilterApplied: false,
  data: null,
  label: "",
};

const DICOM_METADATA_TAGS = {
  SOPInstanceUID: "x00080018",
  SOPClassUID: "x00080016",
  StudyInstanceUID: "x0020000d",
  SeriesInstanceUID: "x0020000e",
  Modality: "x00080060",
  PatientID: "x00100020",
  AccessionNumber: "x00080050",
  PatientName: "x00100010",
  PatientGender: "x00100040",
  PatientAge: "x00101010",
  StudyDescription: "x00081030",
  SeriesDescription: "x0008103e",
  StudyDate: "x00080020",
};

const REQUIRED_DICOM_METADATA = [
  "SOPInstanceUID",
  "SOPClassUID",
  "SeriesInstanceUID",
  "StudyInstanceUID",
  "Modality",
  "PatientID",
];

const REGION_LABELS = {
  global: "Global/Others",
  us: "United States",
  sg: "Singapore",
  uk: "United Kingdom",
  eu: "Europe",
};

const REGIONS = {
  us: "us-app.qure.ai",
  eu: "eu-app.qure.ai",
  sg: "sg-app.qure.ai",
  uk: "uk-app.qure.ai",
}

const PERSISTED = {
  isFullWidthWorklist: "isFullWidthWorklist",
  filterObj: "filterObj",
  savedFilterData: "savedFilterData",
  isDarkMode: "isDarkMode"
};

export default {
  PAGE_SIZE,
  GENDERS,
  AI_INFERENCE,
  RN_COMM_CONST,
  STATUS_OPTIONS,
  SCREENS,
  MEDIA_TYPES,
  IMAGE_TYPES,
  DOC_TYPES,
  AUDIO_TYPES,
  PLATFORMS,
  FORMS,
  NOTIFICATION_EVENTS,
  FORM_FIELDS,
  DEEFAULT_FILTER,
  DICOM_METADATA_TAGS,
  REQUIRED_DICOM_METADATA,
  REGION_LABELS,
  REGIONS,
  PERSISTED
};
