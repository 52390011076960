import { Fragment } from "react";
import PropTypes from "prop-types";
import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";
import DebounceInput from "./DebounceInput";

interface DropdownMenuProps {
  options: any[];
  Icon: (props: any) => JSX.Element;
  hasArrow: boolean;
  hasCustomControl: boolean;
  isSearch: boolean;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  menuItemClassName: string;
  disabled: boolean;
}

const DropdownMenu = (props: DropdownMenuProps) => {
  const {
    options,
    Icon,
    hasArrow,
    hasCustomControl,
    isSearch = false,
    search,
    setSearch,
    menuItemClassName = "",
    disabled = false
  } = props;
  return (
    <Menu as="div" className="flex-shrink-0 relative text-base">
      {({ open, close }) => (
        <>
          <div>
            <Menu.Button
              disabled={disabled}
              onClick={(e) => {
                e.stopPropagation();
              }}
              className={classNames(
                "flex group text-gray-200 border-b-2 relative z-10 border-transparent",
                {
                  "!text-gray-100 bg-teal-900 !border-teal-500":
                    !hasCustomControl && open,
                  "hover:text-gray-100 hover:bg-teal-900 ": !hasCustomControl && !open,
                  "border-none": isSearch,
                  "opacity-50": disabled
                }
              )}
            >
              <Icon open={open} />
              {hasArrow && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.7071 10.7071C17.0976 10.3166 17.0976 9.68342 16.7071 9.29289C16.3166 8.90237 15.6834 8.90237 15.2929 9.29289L16.7071 10.7071ZM12 14L11.2929 14.7071C11.6834 15.0976 12.3166 15.0976 12.7071 14.7071L12 14ZM8.70711 9.29289C8.31658 8.90237 7.68342 8.90237 7.29289 9.29289C6.90237 9.68342 6.90237 10.3166 7.29289 10.7071L8.70711 9.29289ZM15.2929 9.29289L11.2929 13.2929L12.7071 14.7071L16.7071 10.7071L15.2929 9.29289ZM12.7071 13.2929L8.70711 9.29289L7.29289 10.7071L11.2929 14.7071L12.7071 13.2929Z"
                    fill="white"
                    fillOpacity="0.5"
                  />
                </svg>
              )}
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className={`origin-top-right absolute z-20 max-h-85 overflow-y-auto scrollbar-light dark:scrollbar right-0 mt-3 rounded-md shadow-lg dark:bg-gray-800 bg-gray-100 focus:outline-none border border-gray-700 w-72
                            after:content[''] after:absolute after:right-6 after:-top-4 after:bottom-auto after:border-6 after:border-transparent after:border-b-gray-700 ${menuItemClassName}`}
            >
              {isSearch && (
                <div className="flex px-4 py-3 items-center border-b border-gray-700 justify-between">
                  <div className="w-full">
                    <DebounceInput
                      value={search}
                      onChange={setSearch}
                      placeholder="Search templates"
                      className="rounded-md text-white border w-full border-gray-700"
                    />
                  </div>
                  {/* <p className="text-gray-500 text-md">List Filtered By: CT</p> */}
                </div>
              )}
              {options?.map((item, index) => (
                <Menu.Item key={index}>
                  {({ active }) => {
                    const className = item?.className;
                    return (
                      <>
                        {item?.href ? (
                          <a
                            href={item?.href}
                            className={classNames(
                              "block text-gray-200 hover:bg-teal-800",
                              {
                                [className]: !!className,
                              }
                            )}
                          >
                            {item?.name}
                          </a>
                        ) : (
                          <div
                            className={classNames(
                              "block dark:text-gray-200 text-gray-800 cursor-pointer hover:bg-teal-400 hover:text-gray-100",
                              {
                                [className]: !!className,
                              }
                            )}
                            onClick={(e) => {
                              e.stopPropagation();
                              item?.onClick();
                              close();
                            }}
                          >
                            {item?.name}
                          </div>
                        )}
                      </>
                    );
                  }}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

DropdownMenu.defaultProps = {
  Icon: () => <XMarkIcon className="h-6 w-6 m-5" aria-hidden="true" />,
  hasArrow: false,
  hasCustomControl: false,
};

DropdownMenu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.any.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  Icon: PropTypes.func,
  hasArrow: PropTypes.bool,
  hasCustomControl: PropTypes.bool,
};

export default DropdownMenu;
