import { AxiosRequestConfig } from "axios";
import _ from "lodash";
import axiosConnect from "./axiosConnect";

const X_CSRF = "x-csrftoken";

// simple wrapper around axios connect for simple fetch requests
// you will need to handle the errors from catch
function getService<T>(
  url: string,
  params: Record<any, any> = {},
  paramsSerializer?: (params: any) => string,
  timeout: number = 20000,
  options?: AxiosRequestConfig<any>
) {
  return axiosConnect
    .get<T>(url, { params, paramsSerializer, timeout, ...options })
    .then((response) => {
      return response.data;
    });
}

function postService(url: string, params = {}) {
  return axiosConnect.post(url, params).then(({ data }) => data);
}

function patchService<T>(url: string, body: T, params = {}) {
  return axiosConnect.patch(url, body, { params }).then(({ data }) => data);
}

function putService(url: string, params = {}) {
  return axiosConnect.put(url, params).then(({ data }) => data);
}

function deleteService(url: string, params = {}) {
  return axiosConnect.delete(url, params).then(({ data }) => data);
}

export default { getService, postService, patchService, putService, deleteService, X_CSRF };
