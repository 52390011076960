import _ from "lodash";
import { getUserAgent } from ".";

const onDownloadApp = () => {
  const userAgent = getUserAgent();
  const os = _.get(userAgent, ["os", "name"], "").toLowerCase();
  if (os.includes("mac")) {
    window.open(
      "https://qure-platform-artifacts.s3.ap-south-1.amazonaws.com/desktop/Qure.ai-3.0.0.dmg"
    );
  }
  if (os.includes("win")) {
    window.open(
      "https://qure-platform-artifacts.s3.ap-south-1.amazonaws.com/desktop/Qure.ai+Setup+3.0.0.exe"
    );
  }
  if (os.includes("android")) {
    window.open("https://play.google.com/store/apps/details?id=app.qure.ai");
  }
  if (os.includes("ios")) {
    window.open("https://apps.apple.com/sg/app/qure-ai/id1631113024");
  }
};

export default onDownloadApp;
