import { useEffect, useState } from "react";
import { IMessageEvent, w3cwebsocket as W3CWebSocket } from "websocket";

const useWebsocket = (urlPath: string, isEnabled: boolean) => {
  const [wsData, setWSData] = useState<any>(null);
  const [socket, setSocket] = useState<W3CWebSocket | null>(null);
  // useful in showing the states for the socket connections
  const [isWSConnectionOpen, setConnectionOpen] = useState(false);
  const [isWSConnectionLoading, setConnectionLoading] = useState(true);
  const [isWSConnectionClosed, setConnectionClosed] = useState(false);

  useEffect(() => {
    if (!isEnabled) {
      return;
    }
    let websocket: W3CWebSocket;
    let connectionTimeout: NodeJS.Timeout;

    const connect = () => {
      const socketUrl = process.env.NEXT_PUBLIC_WS_URL + urlPath;
      websocket = new W3CWebSocket(socketUrl);

      websocket.onopen = () => {
        setConnectionOpen(true);
        setConnectionLoading(false);
        clearTimeout(connectionTimeout);
        setSocket(websocket);
      };

      websocket.onerror = () => {
        setConnectionOpen(false);
        setConnectionLoading(false);
        websocket.close();
      };

      websocket.onmessage = (event: IMessageEvent) => {
        if (typeof event.data === "string") {
          const data = JSON.parse(event.data);
          setWSData(data);
        }
      };

      websocket.onclose = (e) => {
        if (!isWSConnectionClosed) {
          console.log("Socket is closed. Trying to reconnect...");
          connectionTimeout = setTimeout(() => {
            connect();
          }, 5000);
        }
      };
    };

    connect();

    return () => {
      if (websocket?.readyState === 1) {
        setConnectionClosed(true);
        setConnectionOpen(false);
        websocket?.close();
      }
    };
  }, []);

  return {
    socket,
    wsData,
    isWSConnectionOpen,
    isWSConnectionLoading,
  };
};

export default useWebsocket;
