import { useQuery } from "react-query";
import servicesUtils from "../utils/servicesUtils";
import { UserInfo } from "../types/api/UserInfo";
import { ErrorResponse } from "../types/api/ErrorResponse";

const useAuthService = () => {
  return useQuery<UserInfo, ErrorResponse>(['profile'], () =>
    servicesUtils.getService<UserInfo>('/profile/')
  )
}

export default useAuthService;
