import classNames from "classnames";
import PropTypes from "prop-types";
import { Switch } from "@headlessui/react";

interface ToggleProps {
  value: boolean;
  onChange: (arg: boolean) => void;
  disabled?: boolean;
}

const Toggle = ({ value, onChange, disabled }: ToggleProps) => {
  return (
    <Switch
      checked={value}
      onChange={!disabled ? onChange : undefined}
      className={classNames(
        "relative inline-flex h-5 w-7 flex-shrink-0 cursor-pointer rounded-full border-2 border-gray-500 transition-colors duration-200 ease-in-out dark:bg-black",
        {
          "opacity-50 !cursor-not-allowed": disabled,
        }
      )}
    >
      <span
        className={classNames(
          "pointer-events-none relative inline-block h-4 w-4 transform rounded-full bg-gray-500 shadow ring-0 transition duration-200 ease-in-out translate-x-0",
          {
            "!translate-x-5 bg-teal-500": value,
          }
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity opacity-100 ease-in duration-200",
            {
              "!opacity-0 !ease-out !duration-100": value,
            }
          )}
        >
          <svg
            width="8"
            height="2"
            viewBox="0 0 8 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1H7"
              stroke="#0C1723"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        <span
          aria-hidden="true"
          className={classNames(
            "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity opacity-0 ease-out duration-100",
            {
              "!opacity-100 !ease-in !duration-200": value,
            }
          )}
        />
      </span>
    </Switch>
  );
};

Toggle.defaultProps = {
  value: false,
  onChange: () => {},
  disabled: false,
};

Toggle.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Toggle;
