import React, { useEffect } from "react";
import { datadogRum } from '@datadog/browser-rum';
import getConfig from 'next/config'

class DatadogProvider extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }
  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  initialiseDatadog = () => {
    const { publicRuntimeConfig } = getConfig()
    datadogRum.init({
      applicationId: "44de3724-18ea-4414-8035-b6ad600e94c0",
      clientToken: "pub3fd4b4fb55fcbb579cd44e74e8dabc2c",
      site: 'datadoghq.eu',
      service:'platform-frontend',
      version:`${publicRuntimeConfig?.version}.${process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA}`,
      env:window.location.hostname,
      sessionSampleRate:100,
      sessionReplaySampleRate: 100,
      trackLongTasks: true,
      trackResources: true,
      trackUserInteractions: true,
      //trackViewsManually: true,
      defaultPrivacyLevel:'mask-user-input'
    });
    datadogRum.startSessionReplayRecording();
  }

  componentDidMount() {
    if(process.env.NEXT_PUBLIC_APP_ENV === "production") {
      this.initialiseDatadog();
    }
  }

  componentDidUpdate(prevProps) {
    const{ user: prevUser = {}}= prevProps
    const {user = {}} = this.props
    if(prevUser.email !== user.email && process.env.NEXT_PUBLIC_APP_ENV === "production") {
      datadogRum.setUser({
        name: user.name,
        email: user.email,
      });
    }
  }

  componentDidCatch(error, errorInfo) {
    if(process.env.NEXT_PUBLIC_APP_ENV === "production") {
      datadogRum.addError(
        error,
        {
          scope: "error-boundary"
        },
        "source"
      );
    }
  }

  render() {
    if (this.state.hasError) {
      return <Fallback />
    }
    return this.props.children
  }
}

const Fallback = () => {
  return(
    <div className="flex h-full bg-black flex-col justify-center items-center">
      <div className="text-white">
        Application error: a client-side exception has occurred (please reload the browser)
      </div>
    </div>
  )
}

export default DatadogProvider;
