import classNames from "classnames";

interface ButtonProps {
  children?: React.ReactNode;
  onClick?: (arg: any) => void;
  isPrimary?: boolean;
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  isDanger?: boolean;
  type?: "submit" | "reset" | "button";
}

const Button = (props: ButtonProps) => {
  const {
    children,
    onClick,
    isPrimary,
    isDanger,
    className = "",
    disabled,
    isLoading,
    type = "button",
  } = props;
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled || isLoading}
      className={classNames(
        "inline-flex items-center justify-center text-base font-medium rounded-6 text-gray-100 py-4 px-4",
        {
          "bg-teal-500 hover:bg-teal-600": isPrimary,
          "bg-gray-700 hover:bg-gray-600": !isPrimary,
            "bg-red-600 hover:bg-red-500": isDanger,
          "cursor-progress": isLoading,
          "opacity-50": disabled || isLoading,
          [className]: !!className,
        }
      )}
    >
      {isLoading && (
        <div
          className="border-solid border-white border-2 border-r-transparent animate-spin inline-block w-5 h-5 rounded-full text-blue-600 mr-3"
          role="status"
        />
      )}
      {!!children && children}
    </button>
  );
};

export default Button;
