import { getMessaging, getToken } from "firebase/messaging";

const firebaseCloudMessaging = {
  //initializing firebase messaging
  init: async function () {
    try {
      //requesting notification permission from browser
      const status = await Notification.requestPermission();
      if (status && status === "granted") {
        const VAPID = process.env.NEXT_PUBLIC_VAPID;
        //getting token from FCM
        const fcm_token = await getToken(getMessaging(), { vapidKey: VAPID });
        return fcm_token;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  },
};
export { firebaseCloudMessaging };
