import { useEffect, useState } from "react";
import { get, isEmpty } from "lodash";
import { useStoreState } from "pullstate";
import useAuthService from "../../hooks/useAuthService";
import Store from "../../utils/Store";
import {
  getAppConfig,
  getUserSettings,
  isAndroidWebView,
  isIOSWebView,
} from "../../utils";
import { useRouter } from "next/router";
import { subscribeUserNotification } from "../../utils/pushNotification";
import LoggedInToken from "../../utils/LoggedInToken";
import constants from "../../utils/constants";
import { useTranslation } from "react-i18next";
import servicesUtils from "../../utils/servicesUtils";

declare global {
  interface Window {
    ReactNativeWebView: any;
    electronAPI: any;
    data: any;
    __WB_DISABLE_DEV_LOGS: any;
    isUserGroupOpen: any;
  }
}

type AuthenticatorProps = {
  children: React.ReactNode;
};

const Authenticator = (props: AuthenticatorProps) => {
  const { children } = props;
  const [fcmToken, setFcmToken] = useState("");
  const { data: authData, isError, isLoading } = useAuthService();
  const isLoggedin = !isError && get(authData, ["authenticated"], false);
  const router = useRouter();
  const [urlAuthToken, setUrlAuthToken] = useState<string | string[] | null | undefined>(
    null
  );
  const { appConfig, userSettings } = useStoreState(Store, (s) => ({
    appConfig: s.appConfig,
    userSettings: s.userSettings,
  }));
  const allowAppLoad = isLoggedin && appConfig && userSettings;

  const onFCMMessage: EventListener = (e) => {
    const messageData = JSON.parse(get(e, ["data"], ""));
    const fcmToken = get(messageData, ["fcmToken"], "");
    const isRNTestBuild = get(messageData, ["isRNTestBuild"], false);
    const RNSelectedEnv = get(messageData, ["env"], "PROD");
    if (fcmToken) {
      setFcmToken(fcmToken);
    }
    Store.update((store) => {
      store.isRNTestBuild = isRNTestBuild;
      store.RNSelectedEnv = RNSelectedEnv;
    });
  };

  const { ready } = useTranslation("loading_key", { useSuspense: false });

  useEffect(() => {
    if (router.isReady) setUrlAuthToken(router.query.url_auth_token);
  }, [router.isReady]);

  useEffect(() => {
    // Using web workers to pass fcm token from Android and iOS webviews
    if (isIOSWebView() || isAndroidWebView()) {
      window.addEventListener("message", onFCMMessage); // for iOS
      document.addEventListener("message", onFCMMessage); // for Android
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          event: constants.RN_COMM_CONST.APP_READY,
        })
      );
    }

    return () => {
      window.removeEventListener("message", onFCMMessage);
      document.removeEventListener("message", onFCMMessage);
    };
  }, []);

  useEffect(() => {
    if (isLoggedin) {
      getUserSettings();
      getAppConfig();
    }
  }, [isLoggedin]);

  if (isLoggedin) {
    const isApp = window?.ReactNativeWebView || window?.electronAPI;
    localStorage.removeItem("redirectLogin");

    servicesUtils.getService("/dashboard").then((data) => {
      Store.update((store) => {
        store.dashboardUrl = get(data, ["url"], null);
      });
    });

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          event: constants.RN_COMM_CONST.FLUSH_COOKIES,
        })
      );
    }

    Store.update((store) => {
      // eslint-disable-next-line no-param-reassign
      store.user = get(authData, ["details"], {});
      // eslint-disable-next-line no-param-reassign
      store.userInfo = authData;
    });

    // desktop app integration
    if (window.electronAPI) {
      window.electronAPI.setAPISettings({
        apiURL: process.env.NEXT_PUBLIC_API_URL,
        token: get(authData, ["token"], null),
      });
    }

    if (LoggedInToken.isLoginProgress()) {
      LoggedInToken.removeLogInProgress();
      setTimeout(
        () => {
          subscribeUserNotification(fcmToken);
        },
        isApp ? 3000 : 0
      );
    }

    if (router.pathname === "/landing-page") {
      router.push("/");
      return null;
    }
  }

  if (
    (allowAppLoad ||
      router.pathname === "/landing-page" ||
      router.pathname === "/open-app" ||
      router.pathname === "/notifications-widget" ||
      urlAuthToken) &&
    !isLoading &&
    ready
  ) {
    return children;
  }

  return (
    <div className="flex items-center justify-center bold text-black dark:text-white bg-gray-100 dark:bg-slate-900 w-full h-full">
      Loading...
    </div>
  );
};

export default Authenticator;
