import { useRouter } from "next/router";
import { useEffect } from "react";
import constants from "../utils/constants";

function useCheckDashboardContainer() {
  const { pathname } = useRouter();

  if (pathname === "/dashboard") {
    return true;
  }
  return false;
}

export default useCheckDashboardContainer;
