import classNames from "classnames";
import { getNameInitials } from "../../utils";

interface UserIconProps {
  name?: string;
  className?: string;
}

const UserIcon = (props: UserIconProps) => {
  const { name, className = "" } = props;
  return (
    <div
      className={classNames(
        "rounded-full bg-gray-300 dark:bg-gray-800 flex items-center justify-center font-[14px] text-gray-800 dark:text-white",
        {
          [className]: !!className,
        }
      )}
    >
      {getNameInitials(name)}
    </div>
  );
};

export default UserIcon;
