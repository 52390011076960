import { useStoreState } from "pullstate";
import Modal from "../common/Modal";
import Store from "../../utils/Store";
import Button from "../common/Button";
import RadioList from "../FormComponents/RadioList";
import constants from "../../utils/constants";
import { useEffect, useState } from "react";
import { fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";
import { firebaseApp } from "../../firebase/clientApp";
import Toast from "../Toast";

const RNEnvSelector = () => {
  const [envOptions, setEnvOptions] = useState<
    { label: string | JSX.Element; value: string }[]
  >([]);
  const { isRNEnvSelectorOpen, RNSelectedEnv } = useStoreState(Store, (store) => ({
    isRNEnvSelectorOpen: store.isRNEnvSelectorOpen,
    RNSelectedEnv: store.RNSelectedEnv,
  }));
  const [selectedEnv, setSelectedEnv] = useState<string>(RNSelectedEnv);

  useEffect(() => {
    if (firebaseApp) {
      const remoteConfig = getRemoteConfig(firebaseApp);
      remoteConfig.settings.minimumFetchIntervalMillis = 30000;
      remoteConfig.defaultConfig = require("./remote_config_defaults.json");
      fetchAndActivate(remoteConfig)
        .then(() => {
          const envs = JSON.parse(
            getValue(getRemoteConfig(), "ENVIRONMENTS")?.asString()
          );
          const _envOptions = Object.keys(envs).map((env) => ({
            label: (
              <div className="flex flex-col">
                <div>{env}</div>
                <div className="text-sm text-gray-400">{envs[env]}</div>
              </div>
            ),
            value: env,
          }));
          setEnvOptions(_envOptions);
        })
        .catch((err) => {
          Toast.error("Unable to fetch remote config");
        });
    }
  }, []);

  useEffect(() => {
    setSelectedEnv(RNSelectedEnv);
  }, [RNSelectedEnv]);

  const onChange = (val: string) => {
    setSelectedEnv(val);
  };

  const onClose = () => {
    Store.update((s) => {
      s.isRNEnvSelectorOpen = false;
    });
  };

  const onSelect = () => {
    if (selectedEnv) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          event: constants.RN_COMM_CONST.ENV_SELECTION,
          data: selectedEnv,
        })
      );
      onClose();
    }
  };

  return (
    <Modal
      show={isRNEnvSelectorOpen}
      onClose={onClose}
      title="Select environment"
      className="w-full sm:w-9/12 md:w-8/12 lg:w-6/12 xl:w-5/12"
      closeOnClickAway
    >
      <div className="p-5">
        <div className="text-gray-800 dark:text-gray-200 pb-6">
          <RadioList options={envOptions} value={selectedEnv} onChange={onChange} />
        </div>
        <div className="text-gray-100 text-base space-x-5">
          <Button onClick={onClose} className="grow-1 px-6 py-4">
            Cancel
          </Button>
          <Button
            isPrimary
            disabled={!selectedEnv}
            onClick={onSelect}
            className="grow-1 px-6 py-4"
          >
            Select
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RNEnvSelector;
