import Store from "../../utils/Store";
import Modal from "../common/Modal";
import { clone, get, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Button from "../common/Button";
import TextInput from "../FormComponents/TextInput";
import Toast from "../Toast";
import useSaveGroup from "./services/useSaveGroup";
import useAddUsers from "./services/useAddUsers";
import useRemoveUsers from "./services/useRemoveUsers";
import useUpdateGroup from "./services/useUpdateGroup";
import classNames from "classnames";
import useDeleteGroup from "./services/useDeleteGroup";
import useLeaveGroup from "./services/useLeaveGroup";
import UserIcon from "../common/UserIcon";
import InviteUsers from "../InviteUsers";
import UserRow from "./UserRow";
import DeleteModal from "../AutomatedShare/DeleteModal";
import {
  customRegexValidation,
  startCharCheckValidation,
  validateFields,
} from "../../utils/regexValidation";
import { useTranslation } from "react-i18next";

const CreateUserModal = ({ selectedGroup, isVisible, onClose }) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [collaboratorsList, setCollaboratorsList] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [error, setError] = useState("");
  const isAdmin = get(selectedGroup, ["group_admin"], false);

  const saveGroup = useSaveGroup();
  const addUsers = useAddUsers();
  const removeUsers = useRemoveUsers();
  const updateGroup = useUpdateGroup();
  const deleteGroup = useDeleteGroup();
  const leaveGroup = useLeaveGroup();

  const isEdit = !isEmpty(selectedGroup);
  const { email: usersEmail } = Store.useState((s) => s.user);
  const removedUsers = collaboratorsList
    .filter((user) => user.saved && !user.selected)
    .map((user) => user.email);

  const addedUsers = collaboratorsList
    .filter((user) => !user.saved && user.selected)
    .map((user) => user.email);

  const disableSave =
    (isEmpty(removedUsers) &&
      isEmpty(addedUsers) &&
      name === selectedGroup?.name &&
      description === selectedGroup?.description) ||
    isEmpty(name) ||
    (!isEdit && isEmpty(addedUsers));

  useEffect(() => {
    if (!isEmpty(selectedGroup)) {
      let defaultUsers = get(selectedGroup, ["users"], []).map((user) => ({
        ...user,
        selected: true,
        saved: true,
      }));
      setCollaboratorsList(defaultUsers);
      setName(selectedGroup?.name);
      setDescription(selectedGroup?.description);
    }
  }, [selectedGroup]);

  const onCloseModal = (updated) => {
    setName("");
    setDescription("");
    setCollaboratorsList([]);
    onClose(updated);
    setError();
  };

  const validateForm = () => {
    if (isEmpty(name) || (!isEdit && isEmpty(addedUsers))) {
      return false;
    }
    return true;
  };

  const onSave = async () => {
    const validationResults = validateFields([
      {
        fn: customRegexValidation,
        value: name,
        err: ` ${t("group.validation.name_special_char")},`,
      },
      {
        fn: startCharCheckValidation,
        value: name,
        err: ` ${t("group.validation.name_start")},`,
      },
      {
        fn: customRegexValidation,
        value: description,
        err: ` ${t("group.validation.description_special_char")},`,
      },
      {
        fn: startCharCheckValidation,
        value: description,
        err: ` ${t("group.validation.description_start")},`,
      },
    ]);
    if (validationResults) {
      setError(validationResults);
    }
    try {
      if (validateForm() && !validationResults) {
        let response = null;
        if (isEdit) {
          const payload = {
            id: get(selectedGroup, ["id"], ""),
          };
          if (!isEmpty(addedUsers)) {
            // add users
            payload["emails"] = addedUsers;
            response = await addUsers.mutateAsync(payload);
          }
          if (!isEmpty(removedUsers)) {
            // remove users
            payload["emails"] = removedUsers;
            await removeUsers.mutateAsync(payload);
          }
          if (name !== selectedGroup.name || description !== selectedGroup.description) {
            // update group
            payload["description"] = description;
            payload["name"] = name;
            await updateGroup.mutateAsync(payload);
          }
        } else {
          const payload = {
            name: name,
            description: description,
            emails: addedUsers,
          };
          await saveGroup.mutateAsync(payload);
        }
        let message = get(response, ["message"], []);
        if (!isEmpty(message) && message.length > 1) {
          let warning = message[0];
          message.shift();
          if (message) {
            warning += message.join(", ");
          }
          Toast.warning(warning);
        }
        Toast.success(t("group.toast.saved_successfully"));
        onCloseModal(true);
      }
    } catch (err) {
      const status = get(err, ["response", "status"], 0);
      if (status && (status === 403 || status === 406)) {
        const errorMsg = get(err, ["response", "data", "message"], "");
        Toast.error(errorMsg);
      } else {
        Toast.error(t("group.toast.saved_failed"));
      }
    }
  };

  const onDelete = async () => {
    try {
      const payload = {
        id: get(selectedGroup, ["id"], ""),
      };
      await deleteGroup.mutateAsync(payload);
      Toast.success(t("group.toast.deleted_successfully"));
      onCloseModal(true);
      setDeleteModal(false);
    } catch (err) {
      const status = get(err, ["response", "status"], 0);
      if (status && status === 403) {
        const errorMsg = get(err, ["response", "data", "message"], "");
        Toast.error(errorMsg);
      } else {
        Toast.error(t("group.toast.deleted_failed"));
      }
      setDeleteModal(false);
    }
    deleteGroup;
  };

  const onExitGroup = async () => {
    try {
      const payload = {
        id: get(selectedGroup, ["id"], ""),
      };
      await leaveGroup.mutateAsync(payload);
      Toast.success(t("group.toast.exited_successfully"));
      onCloseModal(true);
    } catch (err) {
      const status = get(err, ["response", "status"], 0);
      if (status && status === 406) {
        const errorMsg = get(err, ["response", "data", "message"], "");
        Toast.error(errorMsg);
      } else {
        Toast.error(t("group.toast.exited_failed"));
      }
    }
  };

  const onToggleCollaborator = ({ email }, update) => {
    const updatedList = collaboratorsList.map((item) => {
      if (item.email === email) {
        if (update.delete) {
          item.selected = !item.selected;
        }
        return item;
      }
      return item;
    });
    setCollaboratorsList(updatedList);
  };

  const onAddNewCollabrators = (_collaborators) => {
    const _collaboratorsList = clone(collaboratorsList);

    _collaborators.forEach((_collaborator) => {
      if (!_collaboratorsList.some((c) => c.email === _collaborator.value)) {
        _collaboratorsList.unshift({
          name: _collaborator.text,
          email: _collaborator.value,
          selected: true,
          saved: false,
        });
      }
    });

    setCollaboratorsList(_collaboratorsList);
  };

  return (
    <>
      <Modal
        title={`${isEdit ? t("edit_group_title") : t("create_group_title")}`}
        onClose={onCloseModal}
        show={isVisible}
        className="flex flex-col w-full h-[90%] sm:w-7/12 md:w-7/12 lg:w-5/12 sm:h-5/6 sm:!max-h-[80%] overflow-hidden"
      >
        <div className="flex flex-col h-full mt-4 text-left overflow-y-auto scrollbar-light dark:scrollbar">
          <div className="mx-5">
            <form>
              <TextInput
                label={t("user_group.name_label")}
                placeholder={t("user_group.name_placeholder")}
                name="groupName"
                id="groupName"
                wrapperClassName="my-5"
                required
                value={name}
                onChange={(val) => setName(val)}
              />
              <TextInput
                label={t("user_group.description_label")}
                placeholder={t("user_group.description_placeholder")}
                name="groupDescription"
                id="groupDescription"
                wrapperClassName="my-5"
                value={description}
                onChange={(val) => setDescription(val)}
              />
            </form>
          </div>
          <div className="flex flex-col h-full mx-5 space-y-4">
            <p className="text-base">{t("user_group.add_members")}</p>
            <InviteUsers onSubmit={onAddNewCollabrators} />
            <div className="bg-gray-300 dark:bg-gray-900 py-2 rounded-lg">
              <div className="sm:h-48 h-60 overflow-y-auto scrollbar-light dark:scrollbar divide-y divide-gray-800">
                {!isEmpty(collaboratorsList) &&
                  collaboratorsList.map((user, index) => {
                    const { email } = user;
                    const disabled = (isEdit && !isAdmin) || usersEmail === email;
                    return (
                      <UserRow
                        key={email}
                        user={user}
                        index={index}
                        disabled={disabled}
                        onChange={onToggleCollaborator}
                      />
                    );
                  })}

                {isEmpty(collaboratorsList) && (
                  <div className="flex flex-col h-full w-full items-center justify-center text-gray-600 cursor-default">
                    {t("user_group.please_add_members")}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="text-red-500 px-6 py-1 text-left mb-4 sm:mb-0">{error}</div>
          <div className="align-bottom border-t border-gray-800 w-full mt-4">
            <div className="flex items-center justify-between flex-row p-4 border-t border-t-gray-600">
              {isEdit && !isAdmin ? (
                <div className="p-4 flex items-center justify-center w-full">
                  <div
                    className="flex items-center justify-center cursor-pointer"
                    onClick={onExitGroup}
                  >
                    <XMarkIcon className="h-6 w-6" />
                    <span className="pl-2">{t("user_group.exit")}</span>
                  </div>
                </div>
              ) : (
                <>
                  <div>
                    <div
                      onClick={() => setDeleteModal(true)}
                      className={classNames("flex cursor-pointer", {
                        hidden: !isAdmin,
                      })}
                    >
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_7460_106779)">
                          <path
                            d="M4.6928 5.24927C4.15843 5.24927 3.72852 5.69019 3.72852 6.23364C3.72852 6.77915 4.15843 7.21802 4.6928 7.21802H5.15887L6.12316 20.5358C6.22361 21.9057 7.34057 22.968 8.68655 22.968H16.7705C18.1157 22.968 19.2338 21.9057 19.3335 20.5358L20.2998 7.21802H20.7642C21.2966 7.21802 21.7285 6.77915 21.7285 6.23364C21.7285 5.69019 21.2966 5.24927 20.7642 5.24927H17.959L16.4852 2.99095C16.0674 2.3518 15.3642 1.96802 14.6129 1.96802H10.8441C10.0928 1.96802 9.38968 2.35184 8.97182 2.99095L7.49726 5.24927H4.6928ZM14.6129 3.93677C14.7174 3.93677 14.854 3.99173 14.8781 4.08278L15.6415 5.24927H9.81557L10.579 4.08278C10.6392 3.99173 10.7397 3.93677 10.8441 3.93677H14.6129ZM7.09146 7.21802H18.366L17.4093 20.3922C17.3852 20.7327 17.104 20.9993 16.7705 20.9993H8.68655C8.35307 20.9993 8.07182 20.7327 8.04771 20.3922L7.09146 7.21802Z"
                            fill="#D9DBDD"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_7460_106779">
                            <rect
                              width="18"
                              height="21"
                              fill="white"
                              transform="matrix(-1 0 0 1 21.7285 1.96802)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <span className="pl-2">{t("button.delete")}</span>
                    </div>
                  </div>
                  <div className="flex space-x-4 flex-6">
                    <Button
                      isSecondary
                      className="grow-1 w-24 lg:w-36"
                      onClick={onCloseModal}
                    >
                      {t("button.cancel")}
                    </Button>
                    <Button
                      isPrimary
                      isLoading={
                        saveGroup?.isLoading ||
                        addUsers?.isLoading ||
                        removeUsers?.isLoading ||
                        updateGroup?.isLoading
                      }
                      onClick={onSave}
                      className="sm:mt-0 grow-1 ml-4 w-24 lg:w-36"
                      disabled={disableSave}
                    >
                      {t("button.save")}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <DeleteModal
        deleteModal={deleteModal}
        onDelete={onDelete}
        setDeleteModal={setDeleteModal}
        title={t("delete_user_group.title")}
        content={t("delete_user_group.text")}
      />
    </>
  );
};

CreateUserModal.defaultProps = {};

CreateUserModal.propTypes = {};

export default CreateUserModal;
