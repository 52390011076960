import { FunnelIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";

interface DebounceInputProps {
  value?: string | number;
  onChange?: (arg: any) => void;
  placeholder?: string;
  className?: string;
  hasFilters?: boolean;
  disabled?: boolean;
  inputClassName?: string;
  setShowFilters?: () => void;
  isPrimary?: boolean;
}

const DebounceInput = (props: DebounceInputProps) => {
  const {
    value,
    onChange,
    placeholder,
    className = "",
    inputClassName = "",
    hasFilters,
    disabled = false,
    setShowFilters,
    isPrimary,
  } = props;
  const [text, setText] = useState<string | number | undefined>("");

  useEffect(() => {
    if (value != text) {
      setText(value);
    }
  }, [value]);

  const debounce = <T,>(func: (...args: T[]) => void) => {
    let timer: NodeJS.Timeout | null;
    return (...args: T[]) => {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleChange = (val: string) => {
    setText(val);
    optimizedFn(val);
  };

  const optimizedFn = useCallback(
    debounce((val) => {
      onChange ? onChange(val) : null;
    }),
    []
  );

  return (
    <label
      className={classNames("relative text-gray-500 dark:text-gray-400 block w-full", {
        [className]: !!className,
      })}
    >
      <svg
        className="text-teal-500 dark:text-gray-400 w-6 h-6 absolute top-1/2 transform -translate-y-1/2 left-3 cursor-pointer"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 10C4 6.68629 6.68629 4 10 4C13.3137 4 16 6.68629 16 10C16 13.3137 13.3137 16 10 16C6.68629 16 4 13.3137 4 10ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C11.8487 18 13.551 17.3729 14.9056 16.3199L20.2929 21.7071C20.6834 22.0976 21.3166 22.0976 21.7071 21.7071C22.0976 21.3166 22.0976 20.6834 21.7071 20.2929L16.3199 14.9056C17.3729 13.551 18 11.8487 18 10C18 5.58172 14.4183 2 10 2Z"
          className={classNames("fill-teal-500 dark:fill-gray-100", {
            "dark:fill-teal-400": isPrimary,
          })}
        />
      </svg>
      <input
        value={text}
        className={classNames(
          "form-input w-full p-3 pl-8 pr-8 bg-gray-200 dark:bg-gray-800 rounded-md border-transparent focus:border-transparent text-md",
          {
            " !border-teal-300": !_.isEmpty(text),
            [inputClassName]: !!inputClassName,
            "!border-teal-500 dark:!border-teal-300":
              !_.isEmpty(text) && !!inputClassName,
          }
        )}
        placeholder={placeholder}
        title={placeholder}
        onChange={(e) => handleChange(e.target.value)}
        disabled={disabled}
      />
      {hasFilters && (
        <svg
          onClick={() => {
            setShowFilters ? setShowFilters() : null;
          }}
          className="text-teal-500 dark:text-gray-400 w-6 h-6 absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.1406 4.5C15.0273 4.5 15.75 5.22109 15.75 6.10937C15.75 6.51562 15.5977 6.91016 15.2852 7.20703L11.6523 11.1172V18.2266C11.6523 18.9297 11.1172 19.5 10.4141 19.5C10.1289 19.5 9.91797 19.4258 9.71094 19.2891L6.73047 17.3438C6.32422 17.0547 6.0625 16.5625 6.0625 16.0391V11.1172L2.43047 7.20703C2.15375 6.91016 2 6.51562 2 6.10937C2 5.22109 2.72109 4.5 3.61055 4.5H14.1406ZM7.90234 10.75V15.8711L9.77734 17.1094V10.75C9.77734 10.5117 9.90234 10.2852 10.0625 10.1133L13.5312 6.375H4.2168L7.6875 10.1133C7.84766 10.2852 7.90234 10.5117 7.90234 10.75ZM21.0625 17.3125C21.582 17.3125 22 17.7305 22 18.25C22 18.7695 21.582 19.1875 21.0625 19.1875H15.4375C14.918 19.1875 14.5 18.7695 14.5 18.25C14.5 17.7305 14.918 17.3125 15.4375 17.3125H21.0625ZM14.5 12C14.5 11.4805 14.918 11.0625 15.4375 11.0625H21.0625C21.582 11.0625 22 11.4805 22 12C22 12.5195 21.582 12.9375 21.0625 12.9375H15.4375C14.918 12.9375 14.5 12.5195 14.5 12ZM21.0625 4.8125C21.582 4.8125 22 5.23242 22 5.75C22 6.26953 21.582 6.6875 21.0625 6.6875H17.9375C17.418 6.6875 17 6.26953 17 5.75C17 5.23242 17.418 4.8125 17.9375 4.8125H21.0625Z"
            className="fill-teal-500 dark:fill-teal-400"
          />
        </svg>
      )}
    </label>
  );
};

export default DebounceInput;
