import { getUrlAuthToken } from ".";
import constants from "./constants";
import LoggedInToken from "./LoggedInToken";

const redirectToLogin = () => {
  LoggedInToken.setLogInPorgress();
  /* eslint-disable no-console */
  console.info("Redirecting to login page.");

  const urlAuthToken = getUrlAuthToken();

  if (urlAuthToken) {
    window?.localStorage?.setItem("redirectAfterLogin", window.location.href);
  }

  const next = `${window.location.origin}/open-app/`;
  const apiUrl = process.env.NEXT_PUBLIC_API_URL;
  const loginURL = `${apiUrl}/accounts/login/?next=${next}`;

  if (window.ReactNativeWebView) {
    // react native app
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        event: constants.RN_COMM_CONST.LOGIN,
        data: loginURL,
      })
    );
  } else if (window.electronAPI) {
    // electron app
    window.open(loginURL);
  } else {
    window.location.href = loginURL;
  }
};

export default redirectToLogin;
