import { useStoreState } from "pullstate";
import PropTypes from "prop-types";
import Store from "../../utils/Store";
import Modal from "../common/Modal";
import FINDINGS, { GROUPEDFINDINGS } from "../NotificationSettings/findingsLabels";
import { clone, get, isEmpty, set } from "lodash";
import Checkbox from "../common/Checkbox";
import Toggle from "../common/Toggle";

const FindingsModal = ({ allFindings, selectedFindings, activeCategory, onChange }) => {
  const { isVisible } = useStoreState(Store, (s) => ({
    isVisible: s.isAutomatedFindingsOpen,
  }));

  const onCloseModal = () => {
    Store.update((s) => {
      s.isAutomatedFindingsOpen = false;
    });
  };

  const onChangeMultipleFindings = (value, findings, categoryName) => {
    findings.map((finding)=> {
      onChange(categoryName, finding, value)
    })
  }

  return (
    <Modal
      title={activeCategory === "forms" ? "Forms by Qure.ai" : "AI findings by Qure.ai"}
      onClose={onCloseModal}
      show={isVisible}
      bgOpacity
      className="flex flex-col w-full sm:w-7/12 h-[88%] lg:w-6/12 xl:w-5/12 sm:!h-auto sm:!max-h-[80%] overflow-hidden"
    >
      <div className="p-6 pb-8 overflow-y-auto scrollbar">
        <div>
          {Object.keys(allFindings).map((categoryName) => {
            const findings = allFindings[categoryName];
            const areAllFindingsSelected = isEmpty(findings) ? false
              : Object.keys(findings).every((finding) => selectedFindings && selectedFindings[finding]);
            const groupFindings = get(GROUPEDFINDINGS, categoryName, {});
            let renderedFindings = [];

            return (
              <div key={categoryName} className="flex flex-col">
                <div className="flex my-3 justify-between">
                  <div className="font-bold">
                    {get(FINDINGS, [categoryName], categoryName)}
                  </div>
                  <div className="flex items-center">
                    Select All
                    <div className="mx-4">
                      <Toggle
                        value={areAllFindingsSelected}
                        onChange={(value) => onChangeMultipleFindings(value, Object.keys(findings), categoryName)}
                      />
                    </div>
                  </div>
                </div>
                {Object.keys(groupFindings).map((groupFinding) => {
                  const findingsInGroup = groupFindings[groupFinding];
                  const areAllFindingsInGroupSelected = findingsInGroup.every((finding) => {
                    return selectedFindings && selectedFindings[finding];
                  });
                  renderedFindings = renderedFindings.concat(findingsInGroup);
                  return (
                    <>
                      <Checkbox
                        key={groupFinding}
                        id={groupFinding}
                        className="w-full my-2 pr-2"
                        checked={areAllFindingsInGroupSelected}
                        onChange={(value) => onChangeMultipleFindings(value , findingsInGroup, categoryName)}
                        label={get(FINDINGS, [groupFinding], groupFinding)}
                      />
                      <div className="flex flex-wrap px-6">
                        {findingsInGroup.map((finding) => {
                          const isSelected = selectedFindings && selectedFindings[finding];
                          return (
                            <Checkbox
                              key={finding}
                              id={finding}
                              name={"aiFind"}
                              className="w-full sm:w-1/2 my-2 pr-2"
                              checked={isSelected}
                              onChange={(val) => {
                                onChange(categoryName, finding, val);
                              }}
                              label={get(FINDINGS, [finding], finding)}
                            />
                          );
                        })}
                      </div>
                    </>
                  );
                })}
                <div className="flex flex-wrap">
                  {!isEmpty(findings) &&
                    Object.keys(findings).filter((f) => !renderedFindings.includes(f)).map((finding) => {
                      const isSelected = selectedFindings && selectedFindings[finding];
                      return (
                        <Checkbox
                          key={finding}
                          id={finding}
                          name={"aiFind"}
                          className="w-full sm:w-1/2 my-2 pr-2"
                          checked={isSelected}
                          onChange={(val) => {
                            onChange(categoryName, finding, val);
                          }}
                          label={get(FINDINGS, [finding], finding)}
                        />
                      );
                    })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

FindingsModal.defaultProps = {
  onChange: () => {},
};

FindingsModal.propTypes = {
  findings: PropTypes.object.isRequired,
  onChange: PropTypes.func,
};

export default FindingsModal;
