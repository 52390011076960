import { useStoreState } from "pullstate"
import Store from "../utils/Store"
import Button from "./common/Button"
import Modal from "./common/Modal"

const labelsDefault = {
    title: 'Confirmation modal',
    label: 'Click yes to confirm',
    yes: 'Confirm',
    no:'Cancel'
}
const ConfirmationModal = (props) => {
    const {onClose, onClickConfirm, labels=labelsDefault, isLoading} = props
    const {title, label, yes, no} = labels
    const { isVisible } = useStoreState(Store, s => ({ isVisible: s.isConfirmationModalOpen }))

    return (
        <Modal
            show={isVisible}
            onClose={onClose}
            title={title}
            className="w-full sm:w-4/12 lg:w-3/12"
        >
            <div className="p-6 text-left">
                {label}
            </div>
            <div className='flex items-center flex-col sm:justify-end sm:flex-row p-4 border-t border-t-gray-700'>
                <Button isSecondary className="grow-1 ml-4 w-full sm:w-auto py-4 px-6" onClick={onClose}>{no}</Button>
                <Button isPrimary isLoading={isLoading} className="mt-3 sm:mt-0 grow-1 ml-4 w-full sm:w-auto py-4 px-6" onClick={onClickConfirm}>{yes}</Button>
            </div>
        </Modal>
    )
}

ConfirmationModal.defaultProps = {
    isLoading: false,
    onClickConfirm: ()=>null,
    onClose: ()=>null,
    labels :labelsDefault
}

export default ConfirmationModal