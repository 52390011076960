import classNames from "classnames";
import { clone, filter, isEmpty } from "lodash";
import Checkbox from "../common/Checkbox";

const CheckboxList = (props) => {
  const { label, options, value, onChange, className, required, disabled } = props;

  const onChangeValue = (newVal) => {
    let result = clone(value) || [];
    if (result.includes(newVal)) {
      result = result.filter((r) => r !== newVal);
    } else {
      result.push(newVal);
    }
    onChange(result);
  };

  return (
    <div className="flex flex-col w-full text-gray-900 dark:text-gray-100 text-left">
      <div className={classNames("flex text-base pb-4")}>
        {label}
        {!!required && <span className="text-red-500 pl-2">*</span>}
      </div>
      <div
        className={classNames("flex flex-col", {
          [className]: !!className,
        })}
      >
        {options.map((option) => {
          const { label, value: currentVal, child } = option;
          const isChecked = value.includes(currentVal);
          return (
            <Checkbox
              key={currentVal}
              id={currentVal}
              checked={isChecked}
              label={label}
              child={child}
              onChange={() => onChangeValue(currentVal)}
              className="py-2"
              disabled={disabled}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CheckboxList;
