import Toast from "../components/Toast";
import servicesUtils from "./servicesUtils";
import { getPlatform, isAndroidWebView, isIOSWebView } from ".";
import { firebaseCloudMessaging } from "../utils/webPush";
import { getMessaging, onMessage } from "firebase/messaging";

export const registerSubscription = (token?: string | null) => {
  const params = {
    token,
    platform: getPlatform(),
  };
  return servicesUtils
    .postService("/subscription/", params)
    .then(() => {
      Toast.success("You have been subscribed for notifications.");
    })
    .catch(() => {
      Toast.error("Unable to subscribe for notifications. please try relogin");
    });
};

export const subscribeUserNotification = async (fcmToken: string) => {
  try {
    if (!fcmToken && !isAndroidWebView() && !isIOSWebView()) {
      const token = await firebaseCloudMessaging.init();
      await registerSubscription(token);
      getMessage();
    } else if (fcmToken) {
      // because fcm token is send from andoird / ios native app
      await registerSubscription(fcmToken);
    }
  } catch (error) {
    console.error(error);
  }
  function getMessage() {
    const messaging = getMessaging();
    onMessage(messaging, (message) => {
      console.log("foreground:", message);
    });
  }
};
