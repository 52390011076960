import UAParser from "ua-parser-js";
import { format } from "date-fns";
import { get, isEmpty, set } from "lodash";
import showdown from "showdown";
import mime from "mime-types";
import queryString from "query-string";
import constants from "./constants";
import Store from "./Store";
import servicesUtils from "./servicesUtils";

export const getGender = (value?: number) => {
  return constants.GENDERS.find((g) => g.value == value);
};

export const getUserAgent = (providedUserAgent?: string) => {
  const parser = new UAParser(providedUserAgent);
  return parser.getResult();
};

export const isAndroidWebView = () => {
  const userAgent = getUserAgent();
  const isAndroidOS = userAgent.os.name === "Android";
  const hasVersion = userAgent.ua.toLowerCase().includes("version");
  return isAndroidOS && hasVersion;
};

export const isIOSWebView = () => {
  const userAgent = getUserAgent();
  const hasSupportedModel =
    userAgent.device.vendor === "Apple" &&
    (userAgent.device.model === "iPhone" ||
      userAgent.device.model === "iPod" ||
      userAgent.device.model === "iPad");
  const hasSupportedEngine = userAgent.engine.name === "WebKit";
  const isNotBrowser = !userAgent.browser.name?.includes("Safari");
  return hasSupportedModel && hasSupportedEngine && isNotBrowser;
};

export const isWebView = () => isAndroidWebView() || isIOSWebView();

export const getPlatform = () => {
  if (isAndroidWebView()) {
    return constants.PLATFORMS.ANDROID;
  }
  if (isIOSWebView()) {
    return constants.PLATFORMS.IOS;
  }
  return constants.PLATFORMS.PWA;
};

export const isValidDate = (date: Date) => {
  return date instanceof Date && !Number.isNaN(date.getTime());
};

export const getFormattedDate = (
  date: string,
  dateFormat = "E PP hh:mm a",
  defaultValue = "NA"
) => {
  const dateVal = new Date(date);
  return date && isValidDate(dateVal) ? format(dateVal, dateFormat) : defaultValue;
};

export const truncateMiddle = (
  fullStr: string,
  strLen: number,
  separatorParam: string
) => {
  if (isEmpty(fullStr) || fullStr.length <= strLen) {
    return fullStr;
  }

  const separator = separatorParam || "...";

  const sepLen = separator.length;
  const charsToShow = strLen - sepLen;
  const frontChars = Math.ceil(charsToShow / 2);
  const backChars = Math.floor(charsToShow / 2);

  return (
    fullStr.substr(0, frontChars) + separator + fullStr.substr(fullStr.length - backChars)
  );
};

export const getNameInitials = (name?: string) => {
  if (!!name) {
    const nameSplit = name.split(/\s+|_|(?=[A-Z])/);
    let first = get(nameSplit, [0, 0], "");
    let last = get(nameSplit, [1, 0], "");
    return `${first.toUpperCase()}${last.toUpperCase()}`;
  }
  return "-";
};

export const convertMarkdownToHtml = (markdownString: string) => {
  const converter = new showdown.Converter({
    simpleLineBreaks: true,
    underline: true,
    tables: true,
  });
  markdownString = markdownString.replaceAll("\n", "<br />");
  const htmlString = converter.makeHtml(markdownString);
  return htmlString;
};

export const isDevEnv = () => {
  return process.env.NODE_ENV === "development";
};

export const getUrlAuthToken = () => {
  const params = queryString.parse(window?.location?.search);
  return get(params, ["url_auth_token"], "");
};

export const getIsLoggedIn = () => {
  return !isEmpty(Store.useState((store) => store.user));
};

export const getMediaType = (
  mimeType: string,
  studyUid: string,
  reportUid: string,
  formResponseUid: string
) => {
  const images = constants.IMAGE_TYPES;
  const docs = constants.DOC_TYPES;
  const audios = constants.AUDIO_TYPES;
  const extension = mime.extension(mimeType);
  if (typeof extension === "string" && images.includes(extension)) {
    return { type: constants.MEDIA_TYPES.IMAGE, extension };
  } else if (typeof extension === "string" && docs.includes(extension)) {
    return { type: constants.MEDIA_TYPES.DOCUMENT, extension };
  } else if (typeof extension === "string" && audios.includes(extension)) {
    return { type: constants.MEDIA_TYPES.AUDIO, extension };
  } else if (!!studyUid) {
    return { type: constants.MEDIA_TYPES.STUDY, extension: "" };
  } else if (!!reportUid) {
    return { type: constants.MEDIA_TYPES.REPORT, extension: "" };
  } else if (!!formResponseUid) {
    return { type: constants.MEDIA_TYPES.FORM, extension: "" };
  }
  return { type: "", extension: "" };
};

export const isNonDicomFile = (fileType: string) => {
  const fileTypes = ["png", "jpg", "jpeg"];
  return fileTypes.some((type) => fileType.toLowerCase().includes(type));
};

export const isValidEmail = (text: string) => {
  return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
    text
  );
};

const DEMO_SOURCE_ID = ["demo@qure.ai", "demo.qure.ai"];

export const isDemoSource = (sourceId: string) => {
  return DEMO_SOURCE_ID.includes(sourceId);
};

export const getUserSettings = async () => {
  const settingsData: any = await servicesUtils.getService("/settings/");
  Store.update((store) => {
    // eslint-disable-next-line no-param-reassign
    store.userSettings = settingsData && settingsData.settings ? settingsData : {};
  });
};

export const getAppConfig = async () => {
  const configData = await servicesUtils.getService("/configs/");
  Store.update((store) => {
    // eslint-disable-next-line no-param-reassign
    store.appConfig = configData || {};
  });
  return configData;
};

export const convertBufferToFile = (arrayBuffer: ArrayBuffer, fileName?: string) => {
  const blob = new Blob([arrayBuffer], { type: "application/octet-stream" });
  return new File([blob], fileName ? fileName : "anonymous", {
    type: "application/octet-stream",
  });
};
export const getFileExtension = (path: string) => {
  const splitArr = path.split(".");
  return get(splitArr, [splitArr.length - 1], "").toLocaleLowerCase();
};

export const getCookies = () => {
  const _cookies: string[] = document.cookie.split("; ");
  const allCookies = {};
  _cookies.forEach((cookie) => {
    const [key, val] = cookie.split("=");
    set(allCookies, [key], val);
  });
  return allCookies;
};

export const generatePatientId = () => {
  return Math.random().toString(36).slice(2).toUpperCase();
};
