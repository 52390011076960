import { useRouter } from "next/router";
import { useEffect } from "react";
import constants from "../utils/constants";

function useCheckWorklistContainer() {
  const { pathname } = useRouter();

  if (pathname.includes(constants.SCREENS.WORKLIST) || pathname === "/") {
    return true;
  }
  return false;
}

export default useCheckWorklistContainer;
