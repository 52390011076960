import { useStoreState } from "pullstate";
import { useState } from "react";
import redirectToLogin from "../../utils/redirectToLogin";
import Store from "../../utils/Store";
import Button from "../common/Button";
import Modal from "../common/Modal";
import Toast from "../Toast";
import logoutService from "./services/logoutService";
import { useTranslation } from "react-i18next";

const Logout = () => {
  const { t } = useTranslation();
  const { isVisible } = useStoreState(Store, (s) => ({ isVisible: s.isLogoutOpen }));
  const [isLogingOut, setLoggingOut] = useState(false);

  const onCloseLogout = () => {
    Store.update((s) => {
      s.isLogoutOpen = false;
    });
  };

  const onLogout = () => {
    setLoggingOut(true);
    logoutService()
      .then((response) => {
        // desktop app integration
        if (window.electronAPI) {
          window.electronAPI.setAPISettings({
            apiURL: null,
            token: null,
          });
        }
        window.location.replace(response.keycloak_logout_url);
        setLoggingOut(false);
      })
      .catch(() => {
        setLoggingOut(false);
        Toast.error(t("toast.something_went_wrong"));
        redirectToLogin();
      });
  };

  return (
    <Modal
      show={isVisible}
      onClose={onCloseLogout}
      title="Confirm Logout?"
      className="w-full sm:w-5/12 lg:w-4/12"
      closeOnClickAway
    >
      <div className="p-6">Are you sure you want to logout?</div>
      <div className="flex items-center flex-col sm:justify-end sm:flex-row p-4">
        <Button
          isSecondary
          className="grow-1 ml-4 w-full sm:w-auto"
          onClick={onCloseLogout}
        >
          Cancel
        </Button>
        <Button
          isPrimary
          isLoading={isLogingOut}
          className="mt-3 sm:mt-0 grow-1 ml-4 w-full sm:w-auto"
          onClick={onLogout}
        >
          Logout
        </Button>
      </div>
    </Modal>
  );
};

export default Logout;
