import { Dialog, Transition } from "@headlessui/react";
import { ChevronLeftIcon, XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { Fragment } from "react";

interface ModalProps {
  children: JSX.Element | JSX.Element[];
  show: boolean;
  onClose: () => void;
  className: string;
  title: string;
  closeOnClickAway: boolean;
  closeButton?: JSX.Element;
  isAlert?: boolean;
  isLoading?: boolean;
  bgOpacity?: boolean;
}

const Modal = (props: ModalProps) => {
  const {
    children,
    show,
    onClose,
    className,
    title,
    closeOnClickAway,
    closeButton,
    isAlert,
    isLoading,
    bgOpacity,
  } = props;

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={!!onClose && closeOnClickAway ? onClose : () => {}}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={classNames(
              "fixed inset-0 bg-zinc-900 bg-opacity-50 transition-opacity",
              { "!bg-black !bg-opacity-50 sm:!bg-zinc-900 sm:!bg-opacity-50": bgOpacity }
            )}
          />
        </Transition.Child>

        <div className="fixed flex flex-col h-full inset-0 z-10">
          <div className="flex sm:min-h-full h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  "relative transform shadow-xl transition-all w-full rounded-lg bg-gray-200 dark:bg-gray-800 border border-gray-300 dark:border-gray-700 text-gray-800 dark:text-white",
                  {
                    [className]: !!className,
                  }
                )}
              >
                {(!!title || !!onClose) && (
                  <>
                    <div
                      className={classNames(
                        "flex items-center justify-between p-4 border-b border-gray-300 dark:border-gray-600 rounded-t",
                        {
                          "bg-gray-300 dark:bg-gray-700": isAlert,
                        }
                      )}
                    >
                      <div className="text-xl sm:text-lg">{!!title && title}</div>
                      {!!onClose || !!closeButton ? (
                        !!closeButton ? (
                          closeButton
                        ) : (
                          <XMarkIcon
                            className="block text-black dark:text-white cursor-pointer h-6 w-6"
                            aria-hidden="true"
                            onClick={onClose}
                          />
                        )
                      ) : null}
                    </div>
                  </>
                )}
                {children}
                {isLoading && (
                  <div className="absolute flex flex-col h-full w-full items-center justify-center overflow-hidden bg-black opacity-30">
                    <div
                      className="border-solid border-white border-2 border-r-transparent animate-spin inline-block w-8 h-8 rounded-full text-blue-600 mr-3"
                      role="status"
                    />
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
