import { UserInfo } from "../types/api/UserInfo";
import { DCMIO_JSON_TEMPLATE } from "../types/dcmio-template";
import servicesUtils from "./servicesUtils";

const axios = require("axios");

const DCMIO_BASE_URL = "http://127.0.0.1:7000";
const PLATFORM_API_URL = `${process.env.NEXT_PUBLIC_API_URL}${
  process.env.NEXT_PUBLIC_API_URL?.charAt(process.env.NEXT_PUBLIC_API_URL.length - 1) ===
  "/"
    ? ""
    : "/"
}`;

// putting the base config in code only
const PLATFORM_DCMIO_JSON_TEMPLATE = JSON.stringify({
  version: 1,
  api: {
    base_url: "",
    token: "",
    user: "dcmio_dev",
    source: "dcmio_dev",
    timeout: 180,
    sitename: "",
    num_threads: 5,
  },
  sync: {
    enabled: true,
    frequency: 900,
    server: "https://dcsm.qure.ai/",
    token: "0a68ac0ac6d54ff6fa6780437675c83265b6b267",
    timeout: 30,
    api: {
      path: "config/sync/",
    },
  },
  update: {
    enabled: true,
    type: "auto",
    confirmation: true,
    flavour: "standard",
    version: null,
  },
  system: {
    groups: {
      series: ["CT", "CR", "DX", "DR"],
      instance: [],
    },
    stability: {
      type: "series",
      check_delay: 16,
      process_delay: 15,
    },
  },
  filter: {
    query: "True",
    enabled: false,
  },
  upload: {
    api: {
      path: "studies",
    },
    batch_size: 32,
    compression: {
      enabled: true,
      options: "+eb",
    },
    anonymization: {
      enabled: false,
      fields: [
        "PatientID",
        "PatientName",
        "InstitutionName",
        "InstitutionAddress",
        "ReferringPhysicianName",
        "OperatorsName",
        "PatientBirthDate",
        "StationName",
        "AdditionalPatientHistory",
        "PatientComments",
        "PlateID",
        "AdmissionID",
        "ConfidentialityOnPatientDataDescr",
      ],
      algorithms: {
        default: {
          type: "random",
        },
        PatientID: {
          type: "hash",
          fields: ["PatientID", "StudyID"],
        },
      },
    },
  },
  compute: {
    enabled: false,
    api: {
      path: "compute",
      parameters: {
        recompute: "True",
      },
    },
  },
  results: {
    enabled: true,
    api: {
      path: "results",
    },
    cases: {
      success: [
        {
          status: 200,
          results: {
            success: true,
          },
        },
        {
          status: 400,
          results: {
            success: false,
          },
        },
        {
          status: 500,
          results: {
            success: false,
          },
        },
      ],
      try_again: [
        {
          status: 200,
          results: {
            success: false,
          },
        },
        {
          status: 202,
        },
        {
          status: 409,
        },
      ],
    },
  },
  publishers: [
    {
      type: "logging",
      common: {
        enabled: true,
      },
    },
  ],
  cleanup: {
    enabled: true,
    frequency: 600,
    all_series: {
      delay: 21600,
      enabled: true,
    },
    processed_series: {
      delay: 3600,
      enabled: true,
      creation_delay: 3600,
    },
    processing_tasks: {
      delay: 0,
      enabled: true,
    },
    registration_tasks: {
      delay: 0,
      enabled: true,
    },
  },
});

function populatePlatformJson<T>(platformJson: DCMIO_JSON_TEMPLATE, values: T) {
  platformJson.api = {
    ...platformJson.api,
    ...values,
  };
}

async function postDcmioConfig(config: DCMIO_JSON_TEMPLATE) {
  await axios.post(`${DCMIO_BASE_URL}/config/`, config);
}

// no error checking, im not sure what the contracts of the API are when it succeeds, please do any checking you want
function transformedUserDataToDcmioApi(userData: UserInfo) {
  return {
    token: userData.token,
    user: userData.username,
    source: userData.sources[0],
    base_url: PLATFORM_API_URL,
  };
}

async function getUserData() {
  const result = await servicesUtils.getService<UserInfo>("/accounts/userinfo/");
  return result;
}

async function configureDcmio() {
  const platformJson = JSON.parse(PLATFORM_DCMIO_JSON_TEMPLATE);
  // please use whatever funciton you use for getUserData
  const userData = await getUserData();
  const api = transformedUserDataToDcmioApi(userData);
  populatePlatformJson(platformJson, api);
  await postDcmioConfig(platformJson);
}

export default configureDcmio;
