import classNames from "classnames";
import PropTypes from "prop-types";
import FormControl from "../DynamicForm/FormControl";

interface CheckboxProps {
  id?: string;
  label?: string;
  checked?: boolean;
  onChange?: (arg: any) => void;
  className?: string;
  disabled?: boolean;
  name?: string;
  child?: React.ReactNode;
}

const Checkbox = (props: CheckboxProps) => {
  const { id, label, checked, onChange, className = "", disabled, name, child } = props;
  return (
    <div
      className={classNames("relative flex items-center", {
        "opacity-50": disabled,
        [className]: !!className,
      })}
    >
      <div className="flex items-center">
        <input
          id={id}
          aria-describedby={id}
          name={name ?? id}
          type="checkbox"
          className={classNames(
            "rounded dark:border-gray-400 cursor-pointer text-teal-400 bg-transparent",
            {
              "!cursor-not-allowed": disabled,
            }
          )}
          checked={checked}
          onChange={(e) => (onChange ? onChange(e.target.checked) : null)}
          disabled={disabled}
        />
      </div>
      <label
        htmlFor={id}
        className={classNames(
          "ml-3 text-gray-700 dark:text-gray-200 cursor-pointer text-base text-left w-full break-words",
          {
            "!cursor-not-allowed": disabled,
          }
        )}
      >
        {label}
      </label>
      {child && <FormControl control={child} />}
    </div>
  );
};

export default Checkbox;
