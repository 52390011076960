class LoggedInToken {
  key = "loginInProgress";

  setLogInPorgress = () => {
    window.sessionStorage.setItem(this.key, "yes");
  };

  removeLogInProgress = () => {
    window.sessionStorage.removeItem(this.key);
  };

  isLoginProgress = () => {
    return window.sessionStorage.getItem(this.key) === "yes";
  };
}

export default new LoggedInToken();
