import { useEffect, useState } from "react";
import { clone, get, isEmpty } from "lodash";
import { useStoreState } from "pullstate";
import classNames from "classnames";
import Store from "../../utils/Store";
import Modal from "../common/Modal";
import Image from "next/image";
import Button from "../common/Button";
import TextInput from "../FormComponents/TextInput";
import InviteUsers from "../InviteUsers";
import UserRow from "../UserGroupManagement/UserRow";
import useWorkspacesData from "./services/useWorkspaceData";
import useSingleWorkspace from "./services/useSingleWorkspace";
import useMutateSingleWorkspace from "./services/useMutateSingleWorkspace";
import Toast from "../Toast";
import ConfirmationModal from "../ConfirmationModal";
import { getTrimmedId } from "./utils";
import {
  customRegexValidation,
  emptyFieldValidation,
  startCharCheckValidation,
  validateFields,
} from "../../utils/regexValidation";
import { useTranslation } from "react-i18next";

function WorkspaceManagement() {
  const { t } = useTranslation();
  const [editWorkspaceSelection, setEditWorkspaceSelection] = useState(null);
  const { isVisible } = useStoreState(Store, (s) => ({
    isVisible: s.isWorkspaceOpen,
  }));

  const onCloseModal = () => {
    if (editWorkspaceSelection) {
      setEditWorkspaceSelection("");
    } else {
      Store.update((s) => {
        s.isWorkspaceOpen = false;
      });
    }
  };

  const {
    data: workspaceData,
    isError: isErrorWorkspace,
    isLoading: isLoadingWorkspace,
  } = useWorkspacesData({ isOpen: isVisible });

  const {
    data: singleWorkspaceData,
    isError: isErrorSingleWorkspace,
    isLoading: isLoadingSingleWorkspace,
    error,
  } = useSingleWorkspace({ isOpen: isVisible, id: editWorkspaceSelection });

  const onClickEditWorkspace = (id) => {
    setEditWorkspaceSelection(id);
  };

  return (
    <Modal
      title={t("setting_workspace")}
      onClose={onCloseModal}
      show={isVisible}
      isLoading={isLoadingSingleWorkspace || isLoadingWorkspace}
      className={
        "flex flex-col sm:w-9/12 lg:w-6/12 xl:w-5/12 w-full !min-h-[50%] overflow-hidden"
      }
    >
      {/*<div className='sm:w-9/12 lg:w-6/12 xl:w-6/12 h-4/6'></div>*/}
      <div className="flex flex-col h-full">
        {!editWorkspaceSelection &&
          (isLoadingWorkspace ? (
            <div className="p-8 text-gray-300">
              {t("workspace_managemnet.retrieving")}
            </div>
          ) : (
            <WorkspaceList
              workspaceData={workspaceData}
              onClickEditWorkspace={onClickEditWorkspace}
            />
          ))}

        {editWorkspaceSelection &&
          (isLoadingSingleWorkspace ? (
            <div className="p-8 text-gray-300">
              {t("workspace_managemnet.loading")}: {editWorkspaceSelection}
            </div>
          ) : (
            <EditWorkspace data={singleWorkspaceData} onCloseModal={onCloseModal} />
          ))}
      </div>
    </Modal>
  );
}

const WorkspaceList = (props) => {
  const { workspaceData, onClickEditWorkspace } = props;
  const { t } = useTranslation();
  const { shared_workspaces: sharedWorkspaces, owned_workspaces: ownWorkspaces } =
    workspaceData;

  return (
    <div className="text-left p-4 flex-1">
      <div className="text-gray-800 dark:text-gray-200">
        <div>{t("workspace_managemnet.welcome_text")}</div>
        <div className="text-sm">{t("workspace_managemnet.definition")}</div>
      </div>
      {ownWorkspaces &&
        ownWorkspaces.map((ws) => {
          let workspaceId = get(ws, ["uuid"], "");
          workspaceId = getTrimmedId(workspaceId);
          return (
            <div
              key={workspaceId}
              className="bg-gray-100 dark:bg-gray-900 p-4 rounded-md mt-5 flex items-center justify-between text-[14px] cursor-pointer text-gray-900 dark:text-gray-100"
              onClick={() => onClickEditWorkspace(get(ws, ["id"], ""))}
            >
              <div className="flex items-center">
                <span className="mr-5 uppercase">{workspaceId}</span>
                <span>
                  {ws.name} ({ws.count} {t("members_key")})
                </span>
              </div>
              <div className="flex items-center ">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9.86788 1.16806C10.5997 0.435843 11.7871 0.435843 12.5189 1.16806L12.834 1.48368C13.5658 2.21597 13.5658 3.40321 12.834 4.13498L7.69861 9.273C7.47205 9.49696 7.1934 9.66102 6.88611 9.74957L4.28194 10.4944C4.06319 10.5569 3.82882 10.4944 3.66736 10.3121C3.5085 10.174 3.446 9.93967 3.5085 9.72092L4.2533 7.11675C4.34184 6.80946 4.5059 6.53082 4.72986 6.30425L9.86788 1.16806ZM11.6127 2.05191C11.3913 1.8079 10.9955 1.8079 10.7507 2.05191L9.99288 2.80946L11.1934 4.00998L11.9512 3.22873C12.196 3.00738 12.196 2.61155 11.9512 2.36754L11.6127 2.05191ZM5.45642 7.4605L5.01892 8.98394L6.54236 8.54644C6.64652 8.51779 6.73767 8.46311 6.81319 8.38759L10.3106 4.8928L9.11007 3.69228L5.61527 7.18967C5.53975 7.26519 5.48507 7.35634 5.45642 7.4605ZM5.36007 2.18446C5.70642 2.18446 5.98507 2.46441 5.98507 2.80946C5.98507 3.15582 5.70642 3.43446 5.36007 3.43446H2.4434C1.86814 3.43446 1.40173 3.90061 1.40173 4.47613V11.5595C1.40173 12.135 1.86814 12.6011 2.4434 12.6011H9.52673C10.1023 12.6011 10.5684 12.135 10.5684 11.5595V8.6428C10.5684 8.29644 10.847 8.0178 11.1934 8.0178C11.5398 8.0178 11.8184 8.29644 11.8184 8.6428V11.5595C11.8184 12.8251 10.7924 13.8511 9.52673 13.8511H2.4434C1.17778 13.8511 0.151733 12.8251 0.151733 11.5595V4.47613C0.151733 3.2105 1.17778 2.18446 2.4434 2.18446H5.36007Z" />
                </svg>
              </div>
            </div>
          );
        })}
      <div className="flex items-center my-5 mt-6 w-full">
        <div className="mr-2">{t("workspace_managemnet.shared")}</div>
        <div className="h-[1px] bg-gray-700 flex-1" />
      </div>
      <div className="mb-4">
        {isEmpty(sharedWorkspaces) && (
          <div className="flex justify-center">
            <div className="my-8 text-normal text-gray-600 w-7/12 flex items-center gap-4">
              <div>
                <Image
                  src="/assets/icons/workspaceLight.svg"
                  alt="workspace"
                  width="30"
                  height="30"
                />
              </div>
              <div>{t("workspace_managemnet.no_access")}</div>
            </div>
          </div>
        )}
        <div className="max-h-[300px] overflow-y-auto pb-5">
          {sharedWorkspaces &&
            sharedWorkspaces.map((ws) => {
              let workspaceId = get(ws, ["uuid"], "");
              workspaceId = getTrimmedId(workspaceId);
              return (
                <div
                  key={workspaceId}
                  className="p-4 rounded-md mt-5 flex items-center justify-between border border-gray-300 dark:border-gray-600 text-[14px]
                         text-gray-900 dark:text-white
                         "
                >
                  <div className="flex items-center ">
                    <span className="mr-6 uppercase">{workspaceId}</span>
                    <span>{ws.name}</span>
                  </div>
                  <div
                    className={classNames("flex items-center", {
                      hidden: !ws.owner,
                    })}
                  >
                    <div className="text-sm text-gray-600 dark:text-gray-400">
                      {t("owner_key")}: {ws.owner}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

const EditWorkspace = (props) => {
  const { data, onCloseModal } = props;

  const { t } = useTranslation();
  const workspaceId = get(data, ["id"]);
  const id = getTrimmedId(get(data, ["uuid"]));
  const patientCount = get(data, ["patient_count"]);
  const memberCount = get(data, ["member_count"]);
  const name = get(data, ["name"]);
  const convertResponseToForm = () => {
    const users = _.get(data, ["users"], []);

    return users.map((u) => {
      const permissions = _.get(u, ["permissions"], []);
      return {
        name: _.get(u, ["name"], ""),
        email: _.get(u, ["email"], ""),
        saved: false,
        canUpload: permissions.includes("CREATE_PATIENTS"),
        selected: true,
      };
    });
  };

  const [workspaceName, setWorkspaceName] = useState(name);
  const [isFOrmDirty, setFormDirty] = useState(false);
  const [collaboratorsList, setCollaboratorsList] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    setCollaboratorsList(convertResponseToForm());
    setWorkspaceName(name);
  }, [data]);

  const mutateSingleWorkspace = useMutateSingleWorkspace(workspaceId);
  useEffect(() => {
    if (mutateSingleWorkspace.isSuccess && mutateSingleWorkspace.status === "success") {
      const newList = collaboratorsList.filter((collab) => collab.selected);
      setCollaboratorsList(newList);
    }
  }, [mutateSingleWorkspace.isSuccess]);

  const onClickSave = async () => {
    const validationResults = validateFields([
      {
        fn: emptyFieldValidation,
        value: workspaceName,
        err: ` ${t("workspace_managemnet.validation.empty_field")},`,
      },
      {
        fn: customRegexValidation,
        value: workspaceName,
        err: ` ${t("workspace_managemnet.validation.special_char")},`,
      },
      {
        fn: startCharCheckValidation,
        value: workspaceName,
        err: ` ${t("workspace_managemnet.validation.start")},`,
      },
    ]);
    if (validationResults) {
      setError(validationResults);
    }
    if (isFOrmDirty && !validationResults) {
      // if its dirty then save it
      // for the payload, call post call
      setError("");
      const payload = {
        name: workspaceName,
        user_updates: [],
      };
      payload.user_updates = collaboratorsList.map((collab) => {
        const dot = {
          email: collab.email,
        };

        if (!collab.selected) {
          // means deleting an user
          dot.permissions = [];
        } else {
          dot.permissions = ["WRITE_PATIENTS"];
          if (collab.canUpload) {
            dot.permissions.push("CREATE_PATIENTS");
          }
        }

        return dot;
      });

      try {
        await mutateSingleWorkspace.mutateAsync(payload);
        setFormDirty(false);
        Toast.success(t("workspace_managemnet.toast.success"));
      } catch (e) {
        setFormDirty(false);
        Toast.success(t("workspace_managemnet.toast.failed"));
      }
    }
  };

  const onChangeWorkspaceName = (val) => {
    setWorkspaceName(val);
    if (!isFOrmDirty) {
      setFormDirty(true);
    }
  };
  const onAddNewCollaborators = (_collaborators) => {
    const _collaboratorsList = clone(collaboratorsList);

    _collaborators.forEach((_collaborator) => {
      if (!_collaboratorsList.some((c) => c.email === _collaborator.value)) {
        _collaboratorsList.unshift({
          name: _collaborator.text,
          email: _collaborator.value,
          selected: true,
          canUpload: true,
          saved: false,
        });
      }
    });
    setFormDirty(true);
    setCollaboratorsList(_collaboratorsList);
  };

  const onToggleCollaborator = ({ email }, update) => {
    let hasSet = false;
    const updatedList = collaboratorsList.map((item) => {
      if (item.email === email) {
        if (update.delete) {
          item.selected = !item.selected;
          item.canUpload = false;
          hasSet = true;
        }
        if (update.upload) {
          item.canUpload = !item.canUpload;
          hasSet = true;
        }
        return item;
      }
      return item;
    });

    if (!isFOrmDirty && hasSet) {
      setFormDirty(true);
    }
    setCollaboratorsList(updatedList);
  };

  const onClickCancel = () => {
    if (isFOrmDirty) {
      // open confirmation modal and close this
      Store.update((s) => {
        s.isConfirmationModalOpen = true;
      });
    } else {
      // close the modal
      onCloseModal();
    }
  };

  const onClickConfirmUnsave = () => {
    Store.update((s) => {
      s.isConfirmationModalOpen = false;
    });
    setFormDirty(false);
    onCloseModal();
  };

  return (
    <>
      <div
        className={classNames("text-left p-5 flex-1", {
          "opacity-60 pointer-events-none": mutateSingleWorkspace.isLoading,
        })}
      >
        <div className="flex justify-between items-start">
          <div className="text-gray-800 dark:text-gray-200">
            <div>{t("workspace_managemnet.edit_text")}</div>
            <div className="text-sm">{t("workspace_managemnet.add_remove_text")}</div>
          </div>
          <div className="rounded-md border border-gray-300 px-4 py-2 text-[12px]">
            {patientCount} {t("patients_key")}
          </div>
        </div>
        <TextInput
          label="Workspace Name"
          placeholder="Enter workspace Name"
          name="workspaceName"
          id="workspaceName"
          wrapperClassName="my-5"
          required
          value={workspaceName}
          rightContent={() => {
            return (
              <div className="flex px-2 text-[14px] tracking-wider">
                <div className="text-gray-900 dark:text-gray-100 mr-4">
                  {memberCount} {t("members_key")}
                </div>
                <div className="text-gray-600 dark:text-gray-300 uppercase">{id}</div>
              </div>
            );
          }}
          onChange={(val) => onChangeWorkspaceName(val)}
        />
        <div className="flex flex-col h-full space-y-3">
          <p className="text-base">{t("workspace_managemnet.add_members")}</p>
          <div>
            <InviteUsers onSubmit={onAddNewCollaborators} />
            <div className="text-sm text-gray-800 dark:text-gray-300 mt-2">
              {t("workspace_managemnet.all_users_default")}
            </div>
          </div>

          <div className="bg-gray-300 dark:bg-gray-900 py-2 rounded-lg mt-4">
            <div className="h-48 overflow-y-auto scrollbar-light dark:scrollbar divide-y divide-gray-800">
              {!isEmpty(collaboratorsList) &&
                collaboratorsList.map((user, index) => {
                  const { email } = user;
                  return (
                    <UserRow
                      key={email}
                      user={user}
                      index={index}
                      hasUpload
                      onChange={onToggleCollaborator}
                    />
                  );
                })}

              {isEmpty(collaboratorsList) && (
                <div className="flex flex-col h-full w-full items-center justify-center text-gray-700 cursor-default">
                  {t("workspace_managemnet_no_members")}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="text-red-500 px-6 pb-2 text-left mb-4 sm:mb-0">{error}</div>
      <div className="mt-4 p-4 border-t border-t-gray-700 flex items-center justify-between">
        <div />
        <div className="">
          <Button
            isSecondary
            disabled={mutateSingleWorkspace.isLoading}
            className="mr-4 px-7 py-4"
            onClick={onClickCancel}
          >
            <div className="">{t("button.cancel")}</div>
          </Button>
          <Button
            isPrimary
            isLoading={mutateSingleWorkspace.isLoading}
            className="px-7 py-4"
            disabled={!isFOrmDirty}
            onClick={onClickSave}
          >
            <div className="">{t("button.save")}</div>
          </Button>
        </div>
      </div>
      <ConfirmationModal
        onClose={() => {
          Store.update((s) => {
            s.isConfirmationModalOpen = false;
          });
        }}
        onClickConfirm={onClickConfirmUnsave}
        labels={{
          title: t("confirmation_modal_unsaved"),
          label: t("confirmation_modal_message"),
          yes: t("yes_key"),
          no: t("no_key"),
        }}
      />
    </>
  );
};

export default WorkspaceManagement;
